import React from 'react';
import { toLabel } from './utils';

import 'c3/c3.css';
import ValueFormatter from '../service/value-formatter';
import { Chart } from './chart';

const SUM = 'Summe';

export const GraphViewer = ({
    data,
    grouping,
    labels,
    columns,
    interval,
    showSum,
    graphType,
    graphId = '',
    graphHeight = null,
    showGraphTitle = false,
}) => {
    labels = grouping?.length ? labels[grouping[0]] : [];

    let dateFormat = '%d.%m.%Y';

    if (-1 < interval.indexOf('y')) {
        dateFormat = '%Y';
    } else if (-1 < interval.indexOf('M')) {
        dateFormat = '%m/%Y';
    } else if (-1 < interval.indexOf('w')) {
        dateFormat = '%W';
    }

    const graphData = Object.entries(data)
        .filter(([k]) => '__sums__' !== k || showSum)
        .map(([k, entry]) => [
            '__sums__' === k ? SUM : toLabel(k, labels),
            entry instanceof Array ? entry : entry.__sums__,
        ])
        .sort(([a], [b]) =>
            SUM === a ? -1 : SUM === b ? 1 : a.localeCompare(b)
        );

    // Determine data that could be included in same graph

    const graphs = new Map();
    const uniqueGraphLabels = new Map();

    columns.forEach(({ label, format, aggregation, identifier }, index) => {
        if (!graphs.has(identifier)) {
            graphs.set(identifier, []);
            uniqueGraphLabels.set(identifier, label);
        }
        graphs.get(identifier).push(index);
    });

    const hiddenLabels = [];

    let returnValue = [];

    for (const [graphKey, graphIndexes] of graphs) {
        const columnData = [['x']];

        graphData.forEach((data, index) => {
            if (data[0] === '') {
                data[0] = 'Wert';
                hiddenLabels.push('Wert');
            }

            let flatData = data.flat();
            let flatRow = [];

            flatRow.push(flatData[0]?.v ?? flatData[0]);

            flatData.forEach(function (v, flatIndex) {
                if (graphIndexes.indexOf(flatIndex - 1) > -1) {
                    flatRow.push(v?.v ?? v);
                }
            });

            columnData.push(flatRow);
        });

        graphIndexes.forEach((i) => columnData[0].push(columns[i].date));

        // console.log('COLUMN', columnData, columns);
        const getLabel = () => {
            const size = uniqueGraphLabels.size;
            if (!showGraphTitle || size == 1) {
                return undefined;
            }
            const label = uniqueGraphLabels.get(graphKey);
            const cleanLabel = label.replace(/,\s*\d+$/, '');
            return cleanLabel || '';
        };
        returnValue.push(
            <Graph
                key={graphKey}
                chartId={graphKey}
                columns={columnData}
                formatter={columns[graphIndexes[0]].format}
                format={dateFormat}
                hiddenLabels={hiddenLabels}
                convertedCurrency={
                    columns[graphIndexes[0]]?.convertedCurrency ?? 'EUR'
                }
                graphType={graphType}
                graphId={graphId}
                graphHeight={
                    graphHeight
                        ? Math.max(300, graphHeight / uniqueGraphLabels.size)
                        : graphHeight
                }
                label={getLabel()}
            />
        );
    }

    return (
        <div>
            {returnValue.map((v) => {
                return v;
            })}
        </div>
    );
};

export const Graph = ({
    label,
    columns,
    format,
    formatter,
    hiddenLabels,
    convertedCurrency,
    chartId,
    graphType,
    graphId,
    graphHeight,
}) => {
    return (
        <Chart
            chartId={chartId + (graphType || '') + (graphId || '')}
            config={{
                padding: {
                    right: 30,
                },
                data: {
                    x: 'x',
                    xFormat: '%Y-%m-%d',
                    columns,
                    type: graphType,
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: { format },
                    },
                    y: {
                        tick: {
                            format: (value) =>
                                ValueFormatter.format(
                                    formatter,
                                    value,
                                    convertedCurrency
                                ),
                        },
                    },
                },
                title: {
                    text: label,
                    padding: {
                        top: label ? 10 : 0,
                        bottom: label ? 10 : 0,
                    },
                },
                tooltip: {
                    tick: {
                        format: (value) =>
                            ValueFormatter.format(
                                formatter,
                                value,
                                convertedCurrency
                            ),
                    },
                },
                legend: {
                    hide: hiddenLabels,
                },
                size: {
                    height: graphHeight,
                },
            }}
        />
    );
};

export default GraphViewer;
