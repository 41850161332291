import { useEffect } from 'react';
import c3 from 'c3';

export function Chart({ config, chartId, ...props }) {
    useEffect(() => {
        const chart = c3.generate({
            bindto: chartId != null ? '#' + chartId : '#c3-chart-container',
            ...config,
        });
        return () => {
            chart.destroy();
        };
    });

    return <div id={chartId ?? 'c3-chart-container'} {...props} />;
}
